
import moment from 'moment'
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
  getCurrentInstance
} from 'vue'
import { CAPI } from '@/api/modules/capi'

export default defineComponent({
  setup () {
    const route = getCurrentInstance()?.proxy.$route
    const router = getCurrentInstance()?.proxy.$router
    const state = reactive({
      tabActive: 0,
      tabData1: '',
      tabData2: '',
      tabData3: ''
    })
    watch(
      () => state.tabActive,
      () => {
        if (state.tabActive === 0) document.title = '用户协议_好心情'
        if (state.tabActive === 1) document.title = '隐私保护协议_好心情'
        if (state.tabActive === 2) document.title = '知情同意书_好心情'
      }
    )
    onMounted(() => {
      CAPI.getAgreement({ id: '3' }).then((res1) => {
        state.tabData1 = res1.result
      })
      CAPI.getAgreement({ id: '2' }).then((res2) => {
        state.tabData2 = res2.result
      })
      CAPI.getAgreement({ id: '5' }).then((res3) => {
        state.tabData3 = res3.result
      })
      if (route?.query.tab) {
        state.tabActive = +route?.query.tab
      } else {
        state.tabActive = 0
      }
      if (state.tabActive === 0) document.title = '用户协议_好心情'
    })
    const handleTabClick = (name: string) => {
      router?.replace({
        query: {
          tab: name
        }
      })
    }
    return {
      ...toRefs(state),
      handleTabClick,
      moment
    }
  },
  async asyncData () {
    const res = await Promise.all([
      CAPI.getAgreement({ id: '3' }),
      CAPI.getAgreement({ id: '2' }),
      CAPI.getAgreement({ id: '5' })
    ])
    return {
      tabData1: res[0].result,
      tabData2: res[1].result,
      tabData3: res[2].result
    }
  }
})
