import http from '@/api/http'

export const CAPI = {
  // 协议详情
  getAgreement (params: { id: string }): Promise<any> {
    return http.request({
      baseURL: 'https://capi.haoxinqing.cn',
      url: '/capi/agreement/get',
      method: 'get',
      params
    })
  }
}
